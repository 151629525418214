<template>
  <div class="newsDetails">
    <img
      src="https://cdn.bubbleplan.cn/m_bubble/journalism/Group101892x.png"
      alt=""
      class="newsDetails-titletop"
    />
    <img
      src="https://cdn.bubbleplan.cn/m_bubble/journalism/Frame96172x.png"
      alt=""
      class="newsDetails-back"
      @click="back"
    />
    <div class="newsDetails-Details">
      <div class="newsDetails-Details-name">
        {{ articleDetails.name }}
      </div>

      <div class="flex newsDetails-Details-title flex-center">
        <!-- <div style="margin-right: 80px">来源：</div> -->
        <div>时间：{{ articleDetails.createTime }}</div>
      </div>
      <div
        style="
          margin-top: 26px;
          margin-bottom: 25px;
          width: 100%;
          height: 1px;
          background: rgba(255, 255, 255, 0.5);
        "
      ></div>
      <div
        v-html="articleDetails.description"
        style="font-size: 21px; color: rgb(255, 255, 255)"
      ></div>
      <div class="newsDetails-bottom flex flex-between">
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/journalism/Group92432x.png"
          alt=""
          class="newsDetails-bottom-img"
        />
        <!-- <div class="newsDetails-bottom-text">泡泡计划运营团队</div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: null,
      articleDetails: {},
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.systemNews();
  },
  methods: {
    back() {
      history.back();
    },
    async systemNews() {
      const { data } = await this.$ajax.systemNews(this.id);
      this.articleDetails = data;
    },
  },
};
</script>
<style lang="scss">
.newsDetails::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.newsDetails {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/m_bubble/commodity/131313154242.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 32px;
  position: relative;
  overflow: auto;
  padding-bottom: 40px;
  &-titletop {
    width: 100%;
    height: 276px;
    margin-top: -32px;
  }
  &-Details {
    width: 1246px;
    padding: 48px 34px;
    margin: auto;
    padding-bottom: 0;
    margin-top: 49px;
    &-name {
      color: #ffffff;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      opacity: 0.9;
    }

    &-title {
      color: #eff1e9;
      font-size: 21px;
      margin-top: 15px;
    }
  }
  .newsDetails-back :hover {
    cursor: pointer;
  }
  .newsDetails-back {
    width: 72px;
    height: 72px;
    position: absolute;
    top: 334px;
    left: 339px;
  }
  .newsDetails-bottom {
    width: 100%;
    margin-top: 53px;
    &-img {
      width: 1245px;
      height: 67px;
    }
    &-text {
      color: #5e6c79;
      font-size: 20px;
      line-height: 66px;
    }
  }
}
</style>